'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

type ActionCallback = () => void;

const useOnRouteChange = (action: ActionCallback): void => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const previousPathRef = useRef(pathname);
  const previousSearchParamsRef = useRef(searchParams);

  useEffect(() => {
    // Only run the action if the path or search params actually changed
    if (previousPathRef.current !== pathname || previousSearchParamsRef.current !== searchParams) {
      action();
      previousPathRef.current = pathname;
      previousSearchParamsRef.current = searchParams;
    }
  }, [pathname, searchParams, action]);
};

export { useOnRouteChange };
