import { useEffect, useRef } from 'react';
import { truckMapConfig } from 'truckMapConfig';

export const useStickyVariant = () => {
  if (!truckMapConfig.isClientSideRender) {
    return;
  }

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      document.body.classList.toggle('tmsticky', !entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return ref;
};
