import { makeStyles } from 'lib/makeStyles';

import { AuthGate } from '../../Auth';

const useStyles = makeStyles({
  footerWrapper: ['py-2']
});

const MobileNavigationFooter = () => {
  const styles = useStyles();

  return (
    <div className={styles.footerWrapper}>
      <AuthGate fullWidthButton showClose={false} showBack={true} />
    </div>
  );
};

MobileNavigationFooter.displayName = 'MobileNavigationFooter';

export { MobileNavigationFooter };
