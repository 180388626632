export async function fetchPlaceSearchRedirectUrl(): Promise<string> {
  try {
    const response = await fetch(`/api/places-path`, { method: 'GET' });

    if (!response.ok) {
      throw new Error('Failed to fetch redirect URL');
    }
    const { redirectPath } = await response.json();

    return redirectPath;
  } catch (error) {
    console.error('Error with full details: ', error);
    throw error;
  }
}
